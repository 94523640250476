@import url(https://fonts.googleapis.com/css?family=Nunito:400,700&display=swap);
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.clip-page {
  background: #dcf5ff;
  margin: 0;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Nunito', sans-serif; }

.clip-container {
  display: flex;
  width: 250px;
  height: 400px;
  background: white;
  border-radius: 10px;
  box-shadow: 7px 8px 5px rgba(174, 29, 255, 0.2); }
  .clip-container .clip-inner {
    display: flex;
    flex-direction: column;
    background: #ae1dff;
    background: -webkit-linear-gradient(320deg, #ae1dff 0%, #ff00b8 100%);
    background: linear-gradient(130deg, #ae1dff 0%, #ff00b8 100%);
    border-radius: 10px;
    width: 100%;
    -webkit-clip-path: circle(10% at 0% 0%);
            clip-path: circle(10% at 0% 0%);
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out; }
    .clip-container .clip-inner h1 {
      color: white;
      font-size: 3rem;
      font-weight: 700;
      margin: .7rem; }
    .clip-container .clip-inner p {
      color: white;
      font-size: 1.2rem;
      padding: .5rem; }
  .clip-container:hover .clip-inner {
    -webkit-clip-path: circle(80%);
            clip-path: circle(80%); }

