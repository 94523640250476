.clip-page {
    background: #dcf5ff;
    margin: 0;
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Nunito', sans-serif;
}

.clip-container {
    display: flex;
    width: 250px;
    height: 400px;
    background: white;
    border-radius: 10px;
    box-shadow: 7px 8px 5px rgba(174,29,255, .2);

    .clip-inner {
        display: flex;
        flex-direction: column;
        background: rgb(174,29,255);
        background: linear-gradient(130deg, rgba(174,29,255,1) 0%, rgba(255,0,184,1) 100%);
        border-radius: 10px;
        width: 100%;
        clip-path: circle(10% at 0% 0%);
        transition: all .4s ease-in-out;
        
        h1 {
            color: white;
            font-size: 3rem;
            font-weight: 700;
            margin: .7rem;
        }

        p {
            color: white;
            font-size: 1.2rem;
            padding: .5rem;
        }
    }

    &:hover .clip-inner {
        clip-path: circle(80%);
    }
}